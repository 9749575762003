import React, {useEffect, useState, useCallback} from 'react';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import TokenPage from './pages/TokenPage';
import UserPage from './pages/UserPage';
import RequestPasswordRequestPage from './pages/RequestPasswordRequestPage';

import {CssBaseline, CssVarsProvider} from '@mui/joy';
import {getUserData, logOut, User} from './state/accountApi';
import {wlTheme, smTheme} from './theme';
import CertificatesPage from './pages/CertificatesPage';
import CertificateValidationPage from './pages/CertificateValidation';

import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import RootLayout, {Error} from './layouts/RootLayout';
import Loader from './components/Loader';
import Verify from './pages/Verify';
import {Page} from './page';
import ResetPasswordPage from './pages/ResetPasswordPage';

function App() {
    const [, setActionId] = useState('');
    const [userData, setUserData] = useState<User | undefined>();
    // FIXME: isLoggedIn is implicit state, based on userData === undefined and can be replaced
    // with a local var
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [errors, setErrors] = useState<Error[]>([]);
    const [ref, setRef] = useState('');
    const [sso, setSSO] = useState('');
    const [sig, setSIG] = useState('');

    function removeError(e: Error) {
        setErrors(errors.filter((a) => a !== e));
    }

    const addError = useCallback(
        (e: Error) => {
            setErrors([...errors, e]);
        },
        // eslint-disable-next-line
        [setErrors]
    );

    useEffect(() => {
        const searchParams = new URL(document.location.href).searchParams;
        setRef(searchParams.get('ref') || '');
        setSSO(searchParams.get('sso') || '');
        setSIG(searchParams.get('sig') || '');
        if (isLoggedIn && ref) {
            window.location.replace(`/user/connect/${ref}?sso=${sso}&sig=${sig}`);
        }
    }, [setRef, setSSO, setSIG, isLoggedIn, ref, sig, sso]);
    useEffect(() => {
        setIsLoading(true);
        getUserData()
            .then(([userDataLocal, error]) => {
                if (!error) {
                    setUserData(userDataLocal);
                    setIsLoggedIn(true);
                } else {
                    if (isLoggedIn) addError({message: error});
                    setIsLoggedIn(false);
                }
                setIsLoading(false);
            })
            .catch((_) => {
                // if we fail to get user data, set login status to false
                setIsLoggedIn(false);
            });

        // Intentionally not adding isLoggedIn
        // eslint-disable-next-line
    }, [setIsLoading, setIsLoggedIn, addError, isLoggedIn]);

    useEffect(() => {
        // FIXME: Can we replace this useEffect with a default value for actionId instead?

        const params = new URL(document.location.href).searchParams;
        const actionIdValue = params.get('actionId');
        setActionId(actionIdValue ?? '');
    }, [setActionId]);

    const login = (
        <Login
            setIsLoggedIn={setIsLoggedIn}
            onError={(e: string) => addError({message: e})}
        />
    );

    /* Main navigation */
    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <RootLayout
                    isLoggedIn={isLoggedIn}
                    errors={errors}
                    userData={userData}
                    onRemoveError={removeError}
                    onLogout={() => {
                        logOut().then(() => {
                            setIsLoggedIn(false);
                        });
                    }}
                />
            ),
            children: [
                {
                    path: '',
                    element: isLoggedIn ? <UserPage userData={userData} /> : login,
                },
                {
                    path: 'login',
                    element: isLoggedIn ? <Navigate to={'/'} /> : login,
                },
                {
                    path: 'account',
                    element: isLoggedIn ? <UserPage userData={userData} /> : login,
                },
                {
                    path: 'tokens',
                    element: isLoggedIn ? <TokenPage isLoggedIn={isLoggedIn} /> : login,
                },
                {
                    path: Page.ResetPasswordRequest,
                    element: <RequestPasswordRequestPage />,
                },
                {
                    path: Page.ResetPassword,
                    element: <ResetPasswordPage />,
                },
                {
                    path: 'signup',
                    element: <SignUp onError={(m: string) => addError({message: m})} />,
                },
                {
                    path: 'validate-certificate',
                    element: <CertificateValidationPage onError={addError} />,
                },
                {
                    path: 'certificates',
                    element: isLoggedIn ? <CertificatesPage /> : login,
                },
                {
                    path: 'verify',
                    element: <Verify />,
                },
            ],
        },
    ]);

    return (
        <CssVarsProvider
            theme={process.env.REACT_APP_TARGET === 'sm' ? smTheme : wlTheme}
            defaultMode={process.env.REACT_APP_TARGET === 'sm' ? 'light' : 'dark'}
            modeStorageKey="colorMode"
            disableTransitionOnChange
        >
            <CssBaseline />
            {isLoading && <Loader />}
            {!isLoading && <RouterProvider router={router} />}
        </CssVarsProvider>
    );
}

export default App;
